<template>
    <v-row align="center" justify="center">
        <v-col cols="4">
            <AddSugestao :permissoes="permissoes" />
        </v-col>
        <v-col cols="4">
            <ListaSugestao :usuario="permissoes" :is-ava="avaliador" :is-adm="administrador" />

        </v-col>
        <v-col cols="4">

            <Fluxo />

        </v-col>

        <v-col :xs="12" :sm="12" :md="perm ? 3 : 4" :lg="perm ? 3 : 4" :xl="perm ? 3 : 4" :cols="12" v-if="perm">
            <v-card color="purple lighten-2" class="white--text" height="150">
                <v-card-title>
                    Dashboard
                </v-card-title>
            </v-card>
        </v-col>
    </v-row>
</template>



<script>
import { mapGetters } from 'vuex'
export default {
    name: 'Colaborador',
    props: {
        isAva: { type: Boolean },
        isAdm: { type:Boolean },
    },
    components: {
        ListaSugestao: () => import('./Lista'),
        AddSugestao: () => import('./Add'),
        Fluxo: () => import('./Fluxo')
    },
  
    data() {
        return {
            avaliador:null,
            administrador:null,
            cfg: JSON.parse(localStorage.getItem('cfgu')),
            perms: [1, 2, 3]
        }
    },
    computed: {
        ...mapGetters(['perm', 'permissoes'])
    },
    methods: {
    }
}
</script>